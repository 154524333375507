<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger  p-ml-2"
          v-if="$checkRoles('playersDelete')"
        />
        <Button
          label="نقل"
          icon="pi pi-plus"
          :disabled="selectedItems.length == 0"
          class="p-ml-2 p-button-success  p-ml-2"
          @click="openTrans()"
        />
        <!-- @click="" -->
      </template>

      <template #right>
        <!-- <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/user')"
        /> -->
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <!-- @click="exportCSV($event)" -->
        <vue-excel-xlsx
          :data="listXlsx"
          :columns="columnsx"
          filename="players"
          sheetname="players"
          style="background: transparent;border: none;"
        >
          <Button
            icon="pi pi-download"
            class="p-button-help"
            v-tooltip="'تحميل'"
          />
        </vue-excel-xlsx>
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>
    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers editable-cells-table"
      :rows="100"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      editMode="cell"
      :filters="filters"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه اللاعبين

          <div style="text-align:left">
            <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="اختيار الاعمده"
              style="width: 20em"
            />
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />

      <Column
        field="image"
        header="الصوره الشخصيه"
        v-if="selectedColumns.find((el) => el.field == 'image')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <img :src="$baseUploadURL + slotProps.data.image" class="dt-image" />
        </template>
      </Column>
      <Column
        field="leaguesId.name"
        v-if="selectedColumns.find((el) => el.field == 'leaguesId')"
        header="الدوري"
        :sortable="true"
      />
      <Column
        field="academieId.userName"
        v-if="selectedColumns.find((el) => el.field == 'academieId')"
        header="الاكاديميه"
        filterMatchMode="in"
        filterField="academieId.userName"
        :sortable="true"
      >
        <template #filter>
          <MultiSelect
            v-model="filters['academieId.userName']"
            :options="academieList"
            optionLabel="userName"
            optionValue="userName"
            placeholder="الكل"
            class="p-column-filter"
          >
          </MultiSelect>
        </template>
      </Column>
      <Column
        field="name"
        header="الاسم"
        :sortable="true"
        v-if="selectedColumns.find((el) => el.field == 'name')"
      >
        <template #filter>
          <input class="form-control" id="name" v-model="filters['name']" />
        </template>

        <template #editor="slotProps">
          <InputText
            v-model="slotProps.data[slotProps.column.field]"
            @input="onCellEdit($event, slotProps)"
          />
        </template>
      </Column>
      <Column
        field="nameEn"
        header="الاسم بالانجليزيه"
        :sortable="true"
        v-if="selectedColumns.find((el) => el.field == 'nameEn')"
      >
        <template #filter>
          <input class="form-control" id="nameEn" v-model="filters['nameEn']" />
        </template>

        <template #editor="slotProps">
          <InputText
            v-model="slotProps.data[slotProps.column.field]"
            @input="onCellEdit($event, slotProps)"
          />
        </template>
      </Column>
      <Column
        field="schoolName"
        header="اسم المدرسه"
        :sortable="true"
        v-if="selectedColumns.find((el) => el.field == 'schoolName')"
      >
        <template #filter>
          <input
            class="form-control"
            id="schoolName"
            v-model="filters['schoolName']"
          />
        </template>
      </Column>
      <Column
        field="nationalID"
        header="الرقم القومي"
        :sortable="true"
        v-if="selectedColumns.find((el) => el.field == 'nationalID')"
      >
        <template #filter>
          <input
            class="form-control"
            id="nationalID"
            v-model="filters['nationalID']"
          />
        </template>
      </Column>
      <Column
        field="dateOfBirth"
        header="تاريخ الميلاد"
        :sortable="true"
        v-if="selectedColumns.find((el) => el.field == 'dateOfBirth')"
      >
        <template #filter>
          <input
            class="form-control"
            id="dateOfBirth"
            type="date"
            v-model="filters['dateOfBirth']"
          />
        </template>
      </Column>
      <Column
        field="gender"
        header="النوع"
        :sortable="true"
        v-if="selectedColumns.find((el) => el.field == 'gender')"
      >
        <template #body="slotProps">
          {{ slotProps.data.gender == 'male' ? 'ذكر' : 'انثي' }}
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('/admin/players-print/' + slotProps.data.id)"
            class="p-button-help p-ml-2  p-button-rounded"
            icon="pi pi-download"
            v-tooltip="'طباعه'"
          ></Button>
          <Button
            type="button"
            @click="$router.push('/admin/players-edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            class="p-button-success p-ml-2  p-button-rounded"
            v-tooltip="'تعديل'"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-if="$checkRoles('playersDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
            v-tooltip="'حذف'"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable :value="[slotProps.data]" class="p-datatable-customers">
            <!-- <Column
              field="image"
              header="الصوره الشخصيه"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <img
                  :src="$baseUploadURL + slotProps.data.image"
                  class="dt-image"
                />
              </template>
            </Column> -->

            <Column
              field="image"
              header="البطاقه الشخصيه"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <img
                  :src="$baseUploadURL + slotProps.data.nationalIdCard"
                  class="dt-image"
                  v-if="slotProps.data.nationalIdCard"
                />
                <p v-else>
                  لا يوجد
                </p>
              </template>
            </Column>

            <Column
              field="image"
              header="شهاده الميلاد"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <img
                  :src="$baseUploadURL + slotProps.data.birthCertificate"
                  class="dt-image"
                />
              </template>
            </Column>

            <Column
              field="image"
              header="اثبات القيد"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <img
                  :src="$baseUploadURL + slotProps.data.constraintProve"
                  class="dt-image"
                />
              </template>
            </Column>

            <Column
              field="image"
              header="بطاقه الام"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <img
                  :src="$baseUploadURL + slotProps.data.motherCard"
                  class="dt-image"
                />
              </template>
            </Column>

            <Column
              field="image"
              header="بطاقه الاب"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <img
                  :src="$baseUploadURL + slotProps.data.fatherCard"
                  class="dt-image"
                />
              </template>
            </Column>
          </DataTable>
          <DataTable
            :value="slotProps.data.teams"
            class="p-datatable-customers"
          >
            <Column field="yearsAcademieId.yearId.name" header="السنه" />
            <Column field="name" header="الفرقه" />
          </DataTable>
        </div>
      </template>
    </DataTable>

    <Dialog :visible.sync="display">
      <template #header>
        <h3>النقل</h3>
      </template>
      <div>
        <div class="mb-3">
          <label for="academieTwoId" class="form-label">
            الى اكاديمية
          </label>
          <select
            class="form-select form-control"
            id="academieTwoId"
            name="academieTwoId"
            v-model="body.academieTwoId"
          >
            <option
              v-for="item of academieList"
              :key="item.id"
              :value="item.id"
              >{{ item.userName }}</option
            >
          </select>
        </div>
      </div>
      <template #footer>
        <Button label="تنفيذ" @click="updateAll" icon="pi pi-check" autofocus />

        <Button
          label="الغاء"
          @click="display = false"
          icon="pi pi-times"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columnsx: [
        {
          field: 'id',
          label: 'الكود',
        },

        {
          field: 'academieId',
          label: 'الاكاديميه',
          dataFormat: this.academieFormat,
        },
        {
          field: 'name',
          label: 'الاسم',
        },
        {
          field: 'nameEn',
          label: 'الاسم بالانجليزيه',
        },

        {
          field: 'schoolName',
          label: 'اسم المدرسه',
        },

        {
          field: 'nationalID',
          label: 'الرقم القومي',
        },

        {
          field: 'dateOfBirth',
          label: 'تاريخ الميلاد',
        },

        {
          field: 'gender',
          label: 'النوع',
        },
        {
          field: 'yearName',
          label: 'الموليد',
          // dataFormat: this.teamsFormat,
        },
        {
          field: 'teamName',
          label: 'الفرق',
          // dataFormat: this.teamFormat,
        },
      ],

      // end

      filters: {},
      expandedRows: [],
      id: null,
      list: [],
      academieList: [],
      selectedItems: [],
      selectedColumns: [],
      columns: [
        {
          field: 'image',
          header: 'الصوره',
        },

        {
          field: 'leaguesId',
          header: 'الدوري',
        },
        {
          field: 'academieId',
          header: 'الاكاديميه',
        },
        {
          field: 'name',
          header: 'الاسم',
        },
        {
          field: 'nameEn',
          header: 'الاسم بالانجليزيه',
        },

        {
          field: 'schoolName',
          header: 'اسم المدرسه',
        },

        {
          field: 'nationalID',
          header: 'الرقم القومي',
        },

        {
          field: 'dateOfBirth',
          header: 'تاريخ الميلاد',
        },

        {
          field: 'gender',
          header: 'النوع',
        },
      ],
      loading: true,
      body: {
        academieTwoId: null,
        selectedList: [],
      },
      listXlsx: [],
    };
  },
  methods: {
    openTrans() {
      this.display = true;
      this.body = {
        academieTwoId: null,
        selectedList: [],
      };
    },
    updateAll() {
      this.body.selectedList = this.selectedItems.map((el) => el.id);
      if (this.body.academieTwoId && this.body.selectedList.length > 0) {
        this.$http.post(`players/updateMany`, this.body).then(
          () => {
            this.getData();
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم النقل بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
    academieFormat(value) {
      return value.userName;
    },
    teamsFormat(value) {
      let text = '';
      for (const itm of value) {
        text += ' ' + itm.yearsAcademieId.yearId.name;
      }
      return text;
    },
    teamFormat(value) {
      let text = '';
      for (const itm of value) {
        text += ' ' + itm.name;
      }
      return text;
    },
    // allTeamsFormat(value) {
    //   let text = '';
    //   for (const itm of value) {
    //     text += ' ' + itm.yearsAcademieId.yearId.name + ' | ' + itm.name;
    //   }
    //   return text;
    // },
    onCellEdit(newValue, props) {
      let body = {};
      body[props.column.field] = newValue;
      this.$http.put(`players/${props.data.id}`, body).then(
        () => {
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التعديل بنجاح',
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    getData() {
      this.$http.get(`players/withDeleted`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.listXlsx=[];


          for (const item of response.data) {
            for (const itm of item.teams) {
              this.listXlsx.push({
                yearName: itm.yearsAcademieId.yearId.name,
                teamName: itm.name,
                ...item,
              });
            }
          }

},
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .post(`players/recoverList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.post(`players/recover/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    this.$http.get(`academies`).then((response) => {
      this.academieList = response.data;
    });
    this.getData();
    this.selectedColumns = [
      {
        field: 'image',
        header: 'الصوره',
      },

      {
        field: 'leaguesId',
        header: 'الدوري',
      },
      {
        field: 'academieId',
        header: 'الاكاديميه',
      },
      {
        field: 'name',
        header: 'الاسم',
      },

      {
        field: 'nationalID',
        header: 'الرقم القومي',
      },

      {
        field: 'dateOfBirth',
        header: 'تاريخ الميلاد',
      },
    ];
  },
};
</script>

<style></style>
